import getFirebaseApp from './index'

let functions
const firebaseFunctions = () => {
  if (!functions) {
    const { getFunctions, connectFunctionsEmulator } = require('firebase/functions')
    functions = getFunctions(getFirebaseApp())

    if (location.hostname === 'localhost') {
      connectFunctionsEmulator(functions, 'localhost', 4001)
    }
  }

  return functions
}

export const httpsCallable = async (funName, ...args) => {
  const { httpsCallable } = await import('firebase/functions')
  return httpsCallable(firebaseFunctions(), funName)(...args)
}

export default firebaseFunctions
