import getCollection from './getCollection'
import { startOfDay, endOfDay, eachDayOfInterval, lightFormat, max } from 'date-fns'
import XLSX from 'xlsx'
import { fieldLabels, fieldNames } from 'utils/fields'
import getProps from 'utils/getProps'
import { where, query, getDocs, orderBy } from 'firebase/firestore/lite'

const exportBooking = async ({ slotId, status = [], startDate, endDate = startDate }) => {
  const format = 'ddMMyyyy'
  const snapshot = await getDocs(
    query(
      getCollection(),
      where('slot.id', '==', slotId),
      where('start', '>=', startOfDay(startDate)),
      where('start', '<=', endOfDay(max([endDate, startDate]))),
      where(
        'status',
        'in',
        status.map((s) => s.value)
      ),
      orderBy('start')
    )
  )

  const wb = XLSX.utils.book_new()
  let ws = {}

  eachDayOfInterval({ start: startDate, end: endDate }).map((day) => {
    const sheetName = lightFormat(day, format)
    ws[sheetName] = XLSX.utils.json_to_sheet([], {
      header: ['Booking ID', 'Status', 'Time', 'Paid Amount', 'Paid Currency', 'Name', 'Email', 'Phone'].concat(
        fieldLabels
      ),
    })
    XLSX.utils.book_append_sheet(wb, ws[sheetName], sheetName)
    return null
  })

  if (!snapshot.empty) {
    snapshot.docs.map((d) => {
      const booking = d.data()
      const sheetName = lightFormat(booking.start, format)
      const dataJson = {
        id: booking.id,
        status: booking.getStatus().label,
        date: booking.displayTime(true),
        amount: booking.paidAmount?.amount,
        currency: booking.paidAmount?.currency,
        name: booking.name,
        email: booking.email,
        phone: booking.phone,
      }
      fieldNames.map((n) => (dataJson[n] = getProps(booking, n)))
      XLSX.utils.sheet_add_json(ws[sheetName], [dataJson], { skipHeader: true, origin: -1 })
      return null
    })
  }

  XLSX.writeFile(wb, `${lightFormat(startDate, format)}-${lightFormat(endDate, format)}.xlsx`)
}

export default exportBooking
