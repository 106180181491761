import {
  orderBy,
  documentId,
  where,
  startAfter,
  endAt,
  limit,
  limitToLast,
  query,
  getDocs,
} from 'firebase/firestore/lite'

const getAllRaw = async (props = {}, getCollection, softDeletable = true) => {
  const { pageInfo = {}, where: wheres = [], order } = props
  const { limit: pageLimit, action, firstItem, lastItem } = pageInfo
  let c = getCollection()

  if (softDeletable) {
    c = query(c, where('deletedAt', '==', null))
  }

  if (order) {
    c = query(c, orderBy(...order))
  } else {
    c = query(c, orderBy(documentId(), 'asc'))
  }

  if (limit) {
    c = query(c, limit(pageLimit + 1))
    if (action && action !== 0) {
      c = query(c, action > 0 ? startAfter(lastItem.id) : endAt(firstItem.id), limitToLast(pageLimit + 1))
    }
  }

  if (wheres.length > 0) {
    wheres.map((w) => {
      return (c = query(c, where(...w)))
    })
  }

  return getDocs(c)
}

export default getAllRaw
