import yup from 'utils/yup'
import { defaultStatus } from 'constants/bookings'
import displayTime from 'utils/displayTime'
import BaseModel from 'models/BaseModel'
import { bookingStatuses, statusConfirmed } from 'constants/bookings'

class BookingModel extends BaseModel {
  constructor(
    name = '',
    email = '',
    phone = '',
    remark = '',
    meta = {},
    start = null,
    end = null,
    status = defaultStatus.value,
    slotTimetableId = null,
    userId = null,
    slot = null,
    fee = null,
    paidAmount = null,
    id = null
  ) {
    super()
    this.name = name
    this.email = email
    this.phone = phone
    this.remark = remark
    this.meta = meta
    this.start = start
    this.end = end
    this.status = status
    this.slotTimetableId = slotTimetableId
    this.userId = userId
    this.slot = slot
    this.fee = fee
    this.paidAmount = paidAmount
    this.id = id
  }

  displayTime(timeonly = false, approx = {}) {
    return displayTime(this.start, this.end, timeonly, approx)
  }

  getStatus() {
    return bookingStatuses.find((s) => s.value === this.status)
  }

  needsPayment() {
    return this.status !== statusConfirmed.value && this.fee?.amount > 0
  }

  toJSON() {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone,
      remark: this.remark,
      meta: this.meta,
      start: this.start,
      end: this.end,
      status: this.status,
      slotTimetableId: this.slotTimetableId,
      slot: this.slot,
      fee: this.fee,
      id: this.id,
    }
  }

  toReferenceJSON() {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone,
      status: this.status,
      id: this.id,
    }
  }

  static validationSchema = yup.object().shape(
    {
      name: yup.string().label('Name').required(),
      email: yup
        .string()
        .email()
        .label('Email Address')
        .when(['phone'], {
          is: (phone) => !phone,
          then: yup.string().required('Email or Phone Number is required!'),
          otherwise: yup.string().nullable(),
        }),
      phone: yup
        .string()
        .label('Phone Number')
        .when(['email'], {
          is: (email) => !email,
          then: yup.string().required('Email or Phone Number is required!').phone(),
          otherwise: yup.string().nullable(),
        }),
    },
    [['phone'], ['phone', 'email']]
  )
}

export default BookingModel
