import { lightFormat, isValid, addMinutes, subMinutes } from 'date-fns'

const displayTime = (start, end, timeonly = false, approx) => {
  if (isValid(start) && isValid(end)) {
    return timeonly
      ? `${lightFormat(start, 'hh:mm a')}`
      : `${lightFormat(start, 'dd-MM-yyyy')} [${lightFormat(
          approx?.enabled ? subMinutes(start, approx.minutes) : start,
          'hh:mm a'
        )} - ${lightFormat(approx?.enabled ? addMinutes(end, approx?.minutes) : end, 'hh:mm a')}]`
  }

  return ''
}

export default displayTime
