import React, { forwardRef, useState } from 'react'
import { publicStatuses } from 'constants/bookings'
import classnames from 'classnames'
import Slots from './slots'
import DatePicker from 'components/DatePicker'
import { SLOT_PUBLIC_LABEL } from 'config/app'
import { GridContainer, GridItem } from 'components/Grid'
import { addDays } from 'date-fns'
import useStyles from './styles'
import useSlotStyles from './slotStyles'
import { TYPE_OPEN } from 'constants/times'
import { getAllSlotTimetablesBySlotId } from 'actions/slotTimetable'
import { statusAvailable } from 'constants/bookings'
import useApi from 'hooks/useApi'

const SlotSelector = forwardRef(({ defaultDate, settings, onChange, slotId, selectedSlotTimetable }, ref) => {
  const classes = useStyles()
  const slotClasses = useSlotStyles(settings.colors)()

  const [date, setDate] = useState(defaultDate)

  let [slotTimetables = []] = useApi(() => {
    return getAllSlotTimetablesBySlotId({ slotId, day: date })
  }, [slotId, date])

  slotTimetables = slotTimetables
    .sort((a, b) => {
      return a.start < b.start ? -1 : 1
    })
    .filter((t) => t.type === TYPE_OPEN && (!settings.hideBookedSlots || t.isBookable()))

  const onDateChange = (d) => {
    setDate(d)
  }

  const onSelect = (slotTimetable) => {
    if (slotTimetable.isBookable() || selectedSlotTimetable?.id === slotTimetable.id) {
      onChange(slotTimetable)
      slotTimetables.forEach((t) => (t.selected = false))
      slotTimetable.selected = true
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4} className={classes.calendar}>
        <div className={classes.calendar}>
          <DatePicker
            label={`Choose a ${SLOT_PUBLIC_LABEL}`}
            name="date"
            minDate={defaultDate}
            value={date}
            maxDate={addDays(defaultDate, settings.advancedBookingDays)}
            onChange={onDateChange}
            fullWidth
            calendar
          />
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <Slots
          slotTimetables={slotTimetables}
          onSelect={onSelect}
          classes={classes}
          slotClasses={slotClasses}
          selectedSlotTimetable={selectedSlotTimetable}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        {!settings.hideBookedSlots && (
          <>
            {publicStatuses.map(({ value, label }) => (
              <div className={classes.indicatorContainer} key={value}>
                <div className={classnames(classes.indicator, slotClasses[value])} /> {label}
              </div>
            ))}
            {selectedSlotTimetable && (
              <div className={classes.indicatorContainer}>
                <div className={classnames(classes.indicator, slotClasses[statusAvailable.value], 'current')} />
                {' Selected'}
              </div>
            )}
          </>
        )}
      </GridItem>
    </GridContainer>
  )
})

export default SlotSelector
