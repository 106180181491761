import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class SlotModel extends BaseModel {
  constructor(name = '', description = '', isPrivate = false, fees = [], times = [], deletedAt = null, id = null) {
    super()
    this.name = name
    this.description = description
    this.isPrivate = isPrivate
    this.fees = fees
    this.times = times
    this.deletedAt = deletedAt
    this.id = id
  }

  toReferenceJSON() {
    return {
      name: this.name,
      id: this.id,
    }
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    times: yup.array().label('Times').required(),
  })
}
export default SlotModel
