export const assignSlotPermission = 'assign-slots'

const permissions = [
  { value: 'slots', label: 'Slots' },
  { value: assignSlotPermission, label: 'Assign Slots' },
  { value: 'times', label: 'Times' },
  { value: 'bookings', label: 'Bookings' },
  { value: 'fees', label: 'Fees' },
  { value: 'payments', label: 'Payments' },
  { value: 'reminders', label: 'Reminders' },
  { value: 'templates', label: 'Templates' },
  { value: 'users', label: 'Users' },
  { value: 'roles', label: 'Roles & Permissions' },
  { value: 'settings', label: 'Settings' },
]

export const groupPermissions = [
  { label: 'Slots / Bookings Times / Fees / Payments', value: ['slots', 'times', 'bookings', 'fees', 'payments'] },
  { label: 'Assign user to slots', value: [assignSlotPermission] },
  { label: 'Reminders / Templates', value: ['reminders', 'templates'] },
  { label: 'Users / Roles & Permissions', value: ['users', 'roles'] },
  { label: 'Templates / Settings', value: ['templates', 'settings'] },
]

export default permissions
