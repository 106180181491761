import React from 'react'
import { GridContainer, GridItem } from 'components/Grid'
import { useLoading } from 'hooks/useLoading'
import classnames from 'classnames'
import { statusAvailable } from 'constants/bookings'

const Time = ({ current, slotTimetable, classes, slotClasses, onSelect }) => (
  <GridItem xs={4} sm={3} md={2}>
    <div
      className={classnames(
        classes.time,
        slotClasses[current ? statusAvailable.value : slotTimetable.getType(true)?.value],
        slotTimetable.selected ? 'selected' : '',
        current ? 'current' : ''
      )}
      onClick={() => onSelect(slotTimetable)}
      type={slotTimetable.getType()}
    >
      {slotTimetable.displayTime(true)}
    </div>
  </GridItem>
)

const Slots = ({ slotTimetables, onSelect, classes, slotClasses, selectedSlotTimetable }) => {
  const [loading] = useLoading()

  return (
    <GridContainer>
      {slotTimetables.length > 0 ? (
        slotTimetables.map((slotTimetable, key) => (
          <Time
            key={key}
            onSelect={onSelect}
            slotTimetable={slotTimetable}
            classes={classes}
            slotClasses={slotClasses}
            current={selectedSlotTimetable?.id === slotTimetable.id}
          />
        ))
      ) : (
        <GridItem xs={12} sm={12} md={12}>
          {!loading && <div className={classes.emptyTimes}>N/A</div>}
        </GridItem>
      )}
    </GridContainer>
  )
}

export default Slots
