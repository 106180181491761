import Slot from './index'
import timestamp from 'utils/firebaseTimestamp'

const SlotConverter = {
  toFirestore: (slot) => {
    const { id, fees, ...fs } = slot
    return {
      ...fs,
      fees: fees.map((f) => (f.toFirestoreJSON !== undefined ? f.toFirestoreJSON() : f)),
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const slot = snapshot.data(options)
    return new Slot(
      slot.name,
      slot.description,
      slot.isPrivate,
      slot.fees,
      slot.times,
      slot.deletedAt?.toDate(),
      snapshot.id
    )
  },
}

export default SlotConverter
